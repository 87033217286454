import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AlertService } from 'app/services/alert.service';
import { AuthService } from 'app/services/auth.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private _authService: AuthService,
    private _alertService: AlertService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this._authService.isAuthenticated$.pipe(
        map(isAuth => {
          const user = this._authService.currentUser
          if (!isAuth) {
            this.router.navigate(['/auth/login'])
            return false
          }
         
          return isAuth
        }),
        take(1)
      )
  }
  
}
