import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            {
                id       : 'dashboard',
                title    : 'Dashboard',
                type     : 'item',
                icon     : 'dashboard',
                url      : '/dashboard',
                permissions: ['view dashboards'],
            },
            {
                id       : 'accounts',
                title    : 'Contas',
                type     : 'item',
                icon     : 'groups_black',
                url      : '/accounts',
                permissions: ['view accounts'],
            },
            {
                id       : 'plans',
                title    : 'Planos',
                type     : 'item',
                icon     : 'shopping_cart_black',
                url      : '/plans',
                permissions: ['view plans'],
            },
            {
                id       : 'subscriptions',
                title    : 'Financeiro',
                type     : 'item',
                icon     : 'monetization_on',
                url      : '/payments',
                permissions: ['view subscriptions'],
            },
            {
                id       : 'blog',
                title    : 'Blog',
                type     : 'item',
                icon     : 'rss_feed',
                url      : '/blog/posts',
                permissions: ['view blogs'],
            },
            {
                id       : 'contacts',
                title    : 'Contatos',
                type     : 'item',
                icon     : 'contact_support',
                url      : '/contato-feedback',
                permissions: ['view feedbacks'],
                badge: {
                    title: '',
                    bg   : '#EC0C8E',
                    fg   : '#FFFFFF'
                },
            },
            {
                id       : 'notification',
                title    : 'Notificações',
                type     : 'item',
                icon     : 'notifications',
                url      : '/notifications',
                permissions: ['view notifications'],
            },
            {
                id       : 'alert',
                title    : 'Alertas',
                type     : 'item',
                icon     : 'warning_black',
                url      : '/alerts',
                permissions: ['view alerts'],
            },
            // {
            //     id       : 'whatsapp',
            //     title    : 'Whatsapp',
            //     type     : 'item',
            //     icon     : 'whatsapp',
            //     url      : '/whatsapp',
            //     svgIcon  : 'whatsapp'
            //     // permissions: ['view whatsapp'],
            // },
            {
                id       : 'nps',
                title    : 'NPS',
                type     : 'item',
                icon     : 'star',
                url      : '/nps',
            },
        ]

    },

    {
        id: 'admin',
        title: 'Administração',
        type: 'group',
        permissions: ['view settings'],
        children: [
            // {
            //     id: 'configurations',
            //     title: 'Configurações',
            //     type: 'collapsable',
            //     icon: 'settings',
            //     children: [
            //         {
            //             id: 'common',
            //             title: 'Contatos',
            //             type: 'item',
            //             icon: 'question_answer_black',
            //             url: '/settings/common',
            //             permissions: ['view contacts'],
            //         },
            //         {
            //             id: 'documents',
            //             title: 'Documentos Legais',
            //             type: 'item',
            //             url: '/settings/documents',
            //             permissions: ['view documents'],
            //         },
            //     ]
            // },
            {
                id       : 'cards',
                title    : 'Cards',
                type     : 'item',
                icon     : 'dashboard_customize',
                url      : '/cards',
                permissions: ['view cards'],
            },
            {
                id       : 'faqs',
                title    : 'FAQ',
                type     : 'collapsable',
                icon     : 'help',
                children: [
                    {
                        id: 'common',
                        title: 'Perguntas e Respostas',
                        type: 'item',
                        url: '/faqs',
                        // icon     : 'help',
                    },
                    {
                        id       : 'faq_categories',
                        title    : 'Categoria de FAQ',
                        type     : 'item',
                        // icon     : 'help',
                        url      : '/faq-categories',
                    },

                ],
            },
            {
                id: 'users',
                title: 'Usuários',
                type: 'item',
                icon: 'vpn_key',
                url: '/users',
                permissions: ['view users'],
            },
        ]
    },{
        id: 'admin',
        title: 'Sistema',
        type: 'group',
        permissions: ['view settings'],
        children: [
            {
                id       : 'logs-acao',
                title    : 'Logs de ação',
                type     : 'item',
                icon     : 'speaker_notes_black',
                url      : '/logs-errors',
                permissions: ['view logs'],
            },
            {
                id       : 'upload-file',
                title    : 'Arquivos',
                type     : 'item',
                icon     : 'folder',
                url      : '/upload-file',
                permissions: ['view files'],
            }
        ]
    }
];
