<mat-toolbar class="p-0 mat-elevation-z1">
    <button
        mat-icon-button
        (click)="toggleSidebar()"
        aria-label="Toggle sidebar"
        class="hamburger-button"
    >
        <mat-icon>menu</mat-icon>
    </button>


    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div
            class=""
            fxFlex="1 0 auto"
            fxLayout="row"
            fxLayoutAlign="end center"
        >
            <button
                mat-button
                [matMenuTriggerFor]="userMenu"
                class="user-button"
            >
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="avatar mr-0 mr-sm-16" src="{{ user.image }}" />
                    <span class="username mr-12" fxHide fxShow.gt-sm>{{
                        user.name
                    }}</span>
                    <mat-icon class="s-16" fxHide.xs
                        >keyboard_arrow_down</mat-icon
                    >
                </div>
            </button>


            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item (click)="profile()">
                    <mat-icon>account_circle</mat-icon>
                    <span>Perfil</span>
                </button>


                <button mat-menu-item (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>
            </mat-menu>


            <div class="toolbar-separator"></div>
        </div>
    </div>
</mat-toolbar>



