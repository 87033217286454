import { Directive, Input, ViewContainerRef, ChangeDetectorRef, TemplateRef, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive({
  selector: '[can]',
  inputs: [ 
    'canOperator'
  ]
})
export class CanDirective implements OnInit, OnDestroy, OnChanges {

  @Input('can')
  public permissions: string[];

  @Input('canOperator')
  public operator: string = 'OR'

    // Private
    private _unsubscribeAll: Subject<any>;

  constructor(
    private viewContainer: ViewContainerRef,
    private changeDetector: ChangeDetectorRef,
    private templateRef: TemplateRef<any>,
    private authService: AuthService) {

        this._unsubscribeAll = new Subject();
     }

     ngOnInit(): void {
      this.viewContainer.clear();

      this.authService.currentUser$
      .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(user => {
        if(user.permissionsNames && this.permissions) {
          this.validatePermissions(user.permissionsNames)
          return
        }

        this.showTemplateBlockInView(null)
      })
     }

     ngOnChanges(changes: SimpleChanges): void {
       const canChanges = changes.can
      if (canChanges && canChanges.firstChange)
        return

      this.authService.currentUser$
        .pipe(take(1))
        .subscribe(user => {
        if(user.permissionsNames && this.permissions) {
          this.validatePermissions(user.permissionsNames)
          return
        } 

        this.showTemplateBlockInView(null)
      })      
    }

    validatePermissions(permissions: string[]) {
      const operator = this.operator == 'AND' ? 'every': 'some'
      const hasPermission = permissions[operator](p => this.permissions.includes(p))
      
      if (hasPermission) {
        this.showTemplateBlockInView(this.templateRef)
      } else {
        this.showTemplateBlockInView(null)
      }
    }

    private showTemplateBlockInView(template: TemplateRef<any>): void {
      this.viewContainer.clear();
      
      if (!template) {
          return;
      }

      this.viewContainer.createEmbeddedView(template);
      this.changeDetector.markForCheck();
  }
  
  ngOnDestroy(): void
  {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }
}
