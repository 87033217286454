import { Directive, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Directive({
  selector: '[copyClipboard]'
})
export class CopyClipboardDirective {

  @Input("copyClipboard")
  public payload: string;

  @Output("onCopy")
  public onCopy: EventEmitter<string> = new EventEmitter<string>();

  constructor(private _snackBar: MatSnackBar) {
    
  }

  @HostListener("click", ["$event"])
  public onClick(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    if (!this.payload)
      return;

    let listener = (e: ClipboardEvent) => {
      let clipboard = e.clipboardData || window["clipboardData"];
      clipboard.setData("text", this.payload.toString());
      e.preventDefault();
      this.onCopy.emit(this.payload);
      this._snackBar.open("Conteúdo copiado.", null, {
        duration: 600
      })
    };

    document.addEventListener("copy", listener, false)
    document.execCommand("copy");
    document.removeEventListener("copy", listener, false);
  }
}
