import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { EnvService } from './services/env.service';
import { fuseConfig } from 'app/config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EnvServiceProvider } from './services/env.service.factory';
import { AuthService } from './services/auth.service';
import { AvatarModule } from 'ngx-avatar';
import { DirectivesModule } from './directives/directives.module';
import { CurrencyPipe } from '@angular/common';
import * as moment from 'moment';

const appRoutes: Routes = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
    { path: 'dashboard', loadChildren: () => import('./main/pages/dashboard/dashboard.module').then(m => m.DashboardModule)},
    { path: 'auth', loadChildren: () => import('./main/pages/auth/auth.module').then(m => m.AuthModule) },
    { path: 'accounts', loadChildren: () => import('./main/pages/accounts/account.module').then(m => m.AccountsModule) },
    { path: 'plans', loadChildren: () => import('./main/pages/plans/plans.module').then(m => m.PlansModule) },
    { path: 'upload-file', loadChildren: () => import('./main/pages/upload-file/upload-file.module').then(m => m.UploadFileModule) },
    { path: 'contato-feedback', loadChildren: () => import('./main/pages/contato-feedback/contato-feedback.module').then(m => m.ContatoFeedbackModule) },
    { path: 'users', loadChildren: () => import('./main/pages/users/users.module').then(m => m.UsersModule) },
    { path: 'subscriptions', loadChildren: () => import('./main/pages/plan-subscriptions/plan-subscriptions.module').then(m => m.PlanSubscriptionsModule) },
    { path: 'payments', loadChildren: () => import('./main/pages/payments/payments.module').then(m => m.PaymentsModule) },
    { path: 'layout-proposal', loadChildren: () => import('./main/pages/layout-proposal/layout-proposal.module').then(m => m.LayoutProposalModule) },
    { path: 'logs-errors', loadChildren: () => import('./main/pages/logs-error/logs-error.module').then(m => m.LogsErrorModule) },
    { path: 'alerts', loadChildren: () => import('./main/pages/alerts/alerts.module').then(m => m.AlertsModule) },
    { path: 'notifications', loadChildren: () => import('./main/pages/notifications/notifications.module').then(m => m.NotificationsModule) },
    { path: 'blog', loadChildren: () => import('./main/pages/blog/blog.module').then(m => m.BlogModule) },
    { path: 'faqs', loadChildren: () => import('./main/pages/faqs/faqs.module').then(m => m.FaqsModule) },
    { path: 'faq-categories', loadChildren: () => import('./main/pages/faqs-categories/faqs-categories.module').then(m => m.FaqsCategoriesModule) },
    { path: 'whatsapp', loadChildren: () => import('./main/pages/whatsapp/whatsapp.module').then(m => m.WhatsappModule) },
    { path: 'cards', loadChildren: () => import('./main/pages/cards/cards.module').then(m => m.CardsModule) },
    { path: 'nps', loadChildren: () => import('./main/pages/nps/nps.module').then(m => m.NpsModule) },
    { path: 'nps-surveys', loadChildren: () => import('./main/pages/nps/surveys/surveys.module').then(m => m.SurveysModule) },
    { path: 'nps-responses', loadChildren: () => import('./main/pages/nps/responses/responses.module').then(m => m.ResponsesModule) },
];

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM/YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MMM/YYYY',
    },
};

moment.locale('pt-BR')

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports     : [
        BrowserModule,
        MatSnackBarModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),

        AvatarModule,

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        SampleModule,
        DirectivesModule.forRoot(),
    ],
    providers: [
        CurrencyPipe,
        // StringFilterByPipe,
        EnvServiceProvider,
        // { provide: LOCALE_ID, useValue: 'pt-BR' },
        // { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }, //you can change useValue
        // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        // { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        // { provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true } }
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
    constructor(_authService: AuthService, envService: EnvService) {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (token && token.length == 64) {
            localStorage.setItem('currentUserToken', token);
        }

        _authService.populate();
    }
}
