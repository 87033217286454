import { EnvService } from './env.service';
import {environment} from "../../environments/environment";
const { hash, tag } = require("../../git-version.json")

export const EnvServiceFactory = () => {
  let version = tag
  if(version && version != "null") version = `${version} -`
  const env = new EnvService();
  env.apiUrl = environment.apiUrl
  env.appName = environment.appName
  env.devMode = !environment.production
  env.storageUrl = environment.storageUrl
  env.appVersion = `${version} ${hash}`
  env.onInit()
  return env;
};

export const EnvServiceProvider = {
  provide: EnvService,
  useFactory: EnvServiceFactory,
  deps: [],
};