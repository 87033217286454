<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<!-- VERTICAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">
    <vertical-layout-1></vertical-layout-1>
</ng-container>
<!-- / VERTICAL LAYOUT 1 -->

<!-- HORIZONTAL LAYOUT 1 -->

<!-- / HORIZONTAL LAYOUT 1 -->


<ng-container *ngIf="(isAuthenticated$ | async)">

    
    <!-- / THEME OPTIONS PANEL -->
    <!-- <button mat-icon-button class="warn mat-elevation-z2 theme-options-button"
        [ngClass]="{'right-side-panel': fuseConfig.layout.sidepanel.position === 'right',
                    'side-panel-hidden': fuseConfig.layout.sidepanel.hidden === true}"
        (click)="toggleSidebarOpen('themeOptionsPanel')">
    <mat-icon>settings</mat-icon>
    </button>

    <fuse-sidebar name="themeOptionsPanel" class="theme-options-sidebar" position="right" [invisibleOverlay]="true">
        <fuse-theme-options></fuse-theme-options>
    </fuse-sidebar> -->
</ng-container>