import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogComponent implements OnInit {

  buttonsMap: [] = []

  constructor(public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.data.buttons = this.data.buttons || []
    this.buttonsMap = this.data.buttons.map((button) => {
      if (typeof button == "string") {
        return {
          label: button,
          class: ""
        }
      }

      return button
    })
  }

  buttonClass(index, button) {
    if(button.class) return button.class;
    return this.data.buttons.length == (index+1) ? 'mat-accent': ''
  }
}
