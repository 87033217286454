
<div fxFlex fxFill fxLayout="column">
    <div class="row" *ngIf="hasSearch && !searchField && !searchComponent">
      <div class="col text-right">
        <mat-form-field [floatLabel]="'never'">
          <input matInput placeholder="{{ searchFieldPlaceholder }}" #inputQuery>
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>
  
    <div class="spinner-container" *ngIf="dataSource.isLoading | async">
      <mat-spinner [diameter]="40"></mat-spinner>
    </div>
  
    <div class="mat-white-bg" style="height: 100%;background-color: #FFF;">
        <table mat-table #table class="w-100-p" [dataSource]="dataSource" [@animateStagger]="{value:'50'}">
            <ng-content></ng-content>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" [@animate]="{value:'*',params:{y:'100%'}}" matRipple (click)="onSelectedRowInternal(row)" ></tr>
          </table>

      <div *ngIf="dataSource.isEmpty | async" class="no-records">
          Nenhum Item Encontrado
      </div>
    </div>
  
  
    <mat-paginator [pageSize]="perPage" [pageIndex]="page" [length]="dataSource.totalItems | async" [pageSizeOptions]="pageSizeOptions"></mat-paginator>


  </div>