<mat-toolbar>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>

        <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
            <a mat-button routerLink="/documentation/getting-started/introduction">Documentation</a>
            <span>&bull;</span>
            <a mat-button routerLink="/documentation/changelog">Changelog</a>
        </div>

    </div>

</mat-toolbar>
