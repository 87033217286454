import { NgModule } from '@angular/core';

import { VerticalLayout1Module } from 'app/layout/vertical/layout-1/layout-1.module';

import { HorizontalLayout1Module } from 'app/layout/horizontal/layout-1/layout-1.module';
import {DialogModule} from "./components/dialog/dialog.module";
import { TableModule } from './components/table/table.module';
import { ImageFieldComponent } from './components/image-field/image-field.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';

@NgModule({
    imports: [
        CommonModule,
        VerticalLayout1Module,

        HorizontalLayout1Module,

        DialogModule,
        
        TableModule,

        MatIconModule,
        MatButtonModule
    ],
    exports: [
        MatTableModule,
        MatIconModule,
        MatButtonModule,

        VerticalLayout1Module,

        HorizontalLayout1Module,

        DialogModule,

        TableModule,

        ImageFieldComponent,

        
    ],
    declarations: [ImageFieldComponent]
})
export class LayoutModule
{
}
