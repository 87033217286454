import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaskDirective } from './mask.directive';
import { CanDirective } from './can.directive';
import { CopyClipboardDirective } from './copy-clipboard.directive';



@NgModule({
  declarations: [
    MaskDirective,
    CanDirective,
    CopyClipboardDirective
  ],
  exports:[
    MaskDirective,
    CanDirective,
    CopyClipboardDirective,
  ]
})
export class DirectivesModule
{
    static forRoot() {
       return {
           ngModule: DirectivesModule,
           providers: [],
       };
    }
}
