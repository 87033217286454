import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationItem } from '@fuse/types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

@Component({
    selector   : 'fuse-nav-vertical-item',
    templateUrl: './item.component.html',
    styleUrls  : ['./item.component.scss']
})
export class FuseNavVerticalItemComponent implements OnInit, OnDestroy
{
    @HostBinding('class')
    classes = 'nav-item';

    @Input()
    item: FuseNavigationItem;

    
    public get icon() : string {
        if(this.item && this.item.icon) {
            return this.item.icon.startsWith('svg:') ? this.item.icon.replace("svg:", ""): this.item.icon
        }
        return null
    }

    public get iconType() : string {
        if(this.item && this.item.icon) {
            return this.item.icon.startsWith('svg:') ? "svg": "native"
        }
        return null
    }

    // Private
    private _unsubscribeAll: Subject<any>;

    private _permissions: string[] = null

    /**
     * Constructor
     */

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
         .subscribe(() => {

             // Mark for check
             this._changeDetectorRef.markForCheck();
         });

         this._fuseNavigationService.onNavigationChanged.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._permissions = this._fuseNavigationService.getCurrentPermissions()
                this._changeDetectorRef.markForCheck();
            })
    }

    canShow(item: FuseNavigationItem) {
        if(!item.hidden) {
            if(item.permissions && this._permissions) {
                return this._permissions.some(p => item.permissions.includes(p))
            }

            return !(item.permissions?.length > 0)
        }
        return false
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
