import { Component } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  template: ''
})
export class UnsubscribeComponent {

  protected _unsubscribeAll: Subject<any>;

  constructor() {
    this._unsubscribeAll = new Subject();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}