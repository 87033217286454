import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TableComponent } from './table.component';

import { FuseSharedModule } from '@fuse/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SearchModule } from '../search/search.module';

@NgModule({
    declarations: [
        TableComponent
    ],
    imports     : [
        RouterModule,
        MatTableModule, 
        MatFormFieldModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatInputModule,
        MatSortModule,

        SearchModule,

        FuseSharedModule,
    ],
    exports     : [
        TableComponent,
        MatSortModule,
        MatPaginatorModule
    ]
})
export class TableModule
{
}