import { Decodable } from "./_decodable";

class UserSimple extends Decodable {
    id: number;
    name: string;
    image: string;
    phoneNumber: string;
    createdAt: Date;
    updatedAt: Date;
}

class User extends UserSimple {
    email: string;
    accessToken: string;
    status: string;
    statusFormatted: string;
    statusClass: string;
    totalNewFeedbacks?: number;
    roleNames: any[]
    permissionsNames: any[]
    emailVerifiedAt: Date;

    protected _map = {
        createdAt: (e) => this.parseDate(e),
        updatedAt: (e) => this.parseDate(e),
        emailVerifiedAt: (e) => this.parseDate(e),
    };
}

export { User, UserSimple };
