import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, } from 'rxjs/operators';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private envService: EnvService
  ) { }

  private getHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };

    let currentUserToken = this.localStorageService.getItem('currentUserToken')

    if (currentUserToken) {
      headersConfig['Authorization'] = `Bearer ${currentUserToken}`;
    }
    return new HttpHeaders(headersConfig);
  }

  get(path: string, params: HttpParams | string | {
    [key: string]: any | any[];
  } | null = new HttpParams()): Observable<any> {
    if (typeof params == "string") {
      params = new HttpParams({ fromString: decodeURIComponent(params as string) })
    }

    return this.http.get(
      this.parsedUrl(path),
      { headers: this.getHeaders(), params: params })
      .pipe(catchError(this.formatErrors)
      );
  }

  put(path: string, body: Object = {}): Observable<any> {
    let headers = this.getHeaders()
    if (!(body instanceof FormData)) {
      body = JSON.stringify(body)
    } else {
      headers = headers.delete("content-type")
    }
    return this.http.put(
      this.parsedUrl(path),
      body,
      { headers: headers }
    )
      .pipe(catchError(this.formatErrors));
  }

  post(path: string, body: Object = {}): Observable<any> {
    let headers = this.getHeaders()
    if (!(body instanceof FormData)) {
      body = JSON.stringify(body)
    } else {
      headers = headers.delete("content-type")
    }

    return this.http.post(
      this.parsedUrl(path),
      body,
      { headers: headers }
    )
      .pipe(catchError(this.formatErrors));
  }

  delete(path: string, params: HttpParams | string | {
    [key: string]: any | any[];
  } | null = new HttpParams()): Observable<any> {
    if (typeof params == "string") {
      params = new HttpParams({ fromString: decodeURIComponent(params as string) })
    }

    return this.http.delete(
      this.parsedUrl(path),
      { headers: this.getHeaders(), params: params })
      .pipe(catchError(this.formatErrors)
      );
  }

  private parsedUrl(path: string) {
    let baseUrl: string = this.envService.apiUrl;
    let url = baseUrl.replace(/\/$/, "") + "/" + path.replace(/^\//, "")
    return url.replace(/\/$/, "")
  }

  private formatErrors(err: any) {
    let unknownError = {
      "status": 500,
      "message": "Houve um erro com a requisição.",
      "errors": []
    }
    if (err instanceof HttpErrorResponse) {
      let errorJson = err.error;
      if (errorJson) {
        errorJson.status = err.status
      }
      if (typeof errorJson.error == "string") {
        errorJson.message = errorJson.error;
      }

      return throwError(errorJson || unknownError);
    } else {
      console.info(err)
    }
    return throwError(err || unknownError);
  }
}