import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }


  getItem(key: string) {
    this.removeExpiredItems();
    let itemValue = localStorage.getItem(key);
    if (itemValue && /^{(.*?)}$/.test(itemValue)) {
      let current = JSON.parse(itemValue);
        return current.value;
    }
    return itemValue
  }

  setItem(key: string, value: any, minutes: number = -1) {
    let expires = minutes > 0 ?(new Date().getTime()) + (60000 * minutes): -1;

    localStorage.setItem(key, JSON.stringify({
        "value": value,
        "expires": expires
    }))
  }

  removeItem(...keys: string[]) {
    keys.map(key => localStorage.removeItem(key))
  }

  private removeExpiredItems() {
    let toRemove = [],
      currentDate = new Date().getTime();

    for (let i = 0, j = window.localStorage.length; i < j; i++) {
      let key = localStorage.key(i),
        current: any = localStorage.getItem(key);

      if (current && /^\{(.*?)\}$/.test(current)) {
        current = JSON.parse(current);
        if (current.expires && current.expires > 0 && current.expires <= currentDate) {
          toRemove.push(key);
        }
      }
    }

    for (let i = toRemove.length - 1; i >= 0; i--) {
      localStorage.removeItem(toRemove[i]);
    }

  }
}