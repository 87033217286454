import { Directive, OnDestroy, HostBinding, Input, ElementRef, AfterViewInit } from '@angular/core';
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js';
import { isNumeric } from 'app/helpers/is-numeric';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

const cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
const cnpjMask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
@Directive({
  selector: 'app-mask, [app-mask], [appMask]',
})
export class MaskDirective implements AfterViewInit, OnDestroy {
  @HostBinding('class.app-mask') compClass = true;


  private cnpjMaskOptions = {
    mask: cnpjMask,
    showMask: false,
    guide: false,
  }

  private cpfMaskOptions = {
    mask: cpfMask,
    showMask: false,
    guide: false,
  }
  
  private cpfCnpjMaskOptions = {
    mask: this.cpfCnpjMask,
    showMask: false,
    guide: false,
  }

  private cpfCnpjMask(rawValue: string) {
    let onlyNumber = rawValue.replace(/\D/g, "")
    let onlyNumberLength = onlyNumber.length || 0
    let numberLength = rawValue.length || 0;
    
    if (numberLength > 14 && isNumeric(rawValue[14]) || onlyNumberLength > 11) {
      return cnpjMask;
    } else {
      return cpfMask;
    }
  }

  private dateMaskOptions = {
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    showMask: false,
    guide: false,
  }

  private timeMaskOptions = {
    mask: [/\d/, /\d/, ':', /\d/, /\d/],
    showMask: false,
    guide: false,
  }

  private phoneMaskOptions = {
    mask: this.phoneMask,
    showMask: false,
    guide: false,
  }

  private phoneMask(rawValue: string) {
    let onlyNumber = rawValue.replace(/\D/g, "")
    let onlyNumberLength = onlyNumber.length || 0
    let numberLength = rawValue.length || 0;
    if (numberLength > 14 && isNumeric(rawValue[14]) || onlyNumberLength > 10) {
      return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    } else {
      return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
  }

  private _appMask = {
    mask: [],
    showMask: false,
    guide: true,
    placeholderChar: '_'
  }

  @Input()
  public set appMask(v: any) {
    if (Array.isArray(v) || typeof v == "function") {
      v = {
        mask: v,
        showMask: false,
        guide: true,
        placeholderChar: '_'
      }
    } else if (v == "phone") {
      v = this.phoneMaskOptions
    } else if (v == "cpf") {
      v = this.cpfMaskOptions
    } else if (v == "cnpj") {
      v = this.cnpjMaskOptions
    } else if (v == "cpf_cnpj") {
      v = this.cpfCnpjMaskOptions
    } else if (v == "date") {
      v = this.dateMaskOptions
    } else if (v == "time") {
      v = this.timeMaskOptions
    } else {
      throw `Invalid mask settings to input ${this.element.nativeElement}`;
    }

    this._appMask = v;

    this.updateMask()
  }

  public get appMask(): any {
    return this._appMask
  }

  public maskedInputController;

  private changesSubscription: Subscription

  constructor(private element: ElementRef, private control: NgControl) { }

  private updateMask() {   
    
    if (this.maskedInputController?.textMaskInputElement) {
      setTimeout(() => {
        this.maskedInputController.textMaskInputElement.update()
      }, 10);
    }
  }

  ngOnInit() {
    if (this.control.control) {
      this.changesSubscription = this.control.control.valueChanges.pipe(distinctUntilChanged())
        .subscribe(v => {
          this.updateMask()
        })
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.maskedInputController = textMask.maskInput({
        inputElement: this.element.nativeElement,
        ...this.appMask
      });
    })
  }

  ngOnDestroy() {
    if(this.maskedInputController){
      this.maskedInputController.destroy();
    }
    if (this.changesSubscription) {
      this.changesSubscription.unsubscribe()
    }
  }
}