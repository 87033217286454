import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EnvService {

    public appVersion;
    public appName;
    //
    // public apiHost;
    // public baseUrl;
    public apiUrl;
    public storageUrl;

    public devMode = false;
  
    constructor() {
    }

    onInit() {
        window.document.title = `${this.appName} - ${this.appVersion}`
        if (this.devMode) {
            window.document.body.classList.add('theme-dev')
        }
    }
  }