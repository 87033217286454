
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import { BehaviorSubject, Observable } from 'rxjs';

export interface TableParams {
    filter?: any
    sort?: string
    page?: number
    per_page?: number
}

export class TableDataSource<T> implements DataSource<T> {

    protected itemsSubject = new BehaviorSubject<T[]>([]);
    protected totalItemsSubject = new BehaviorSubject<number>(0);
    protected totalValueSubject = new BehaviorSubject<number>(0);
    protected loadingSubject = new BehaviorSubject<boolean>(false);
    protected emptySubject = new BehaviorSubject<boolean>(false);
    protected perPageSubject = new BehaviorSubject<number>(0);
    protected pageSubject = new BehaviorSubject<number>(0);

    public isLoading = this.loadingSubject.asObservable();
    public isEmpty = this.emptySubject.asObservable();
    public totalItems = this.totalItemsSubject.asObservable();
    public totalValue = this.totalValueSubject.asObservable();

    public params?: TableParams

    connect(collectionViewer: CollectionViewer): Observable<T[]> {
        //(this.itemsSubject);
        return this.itemsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.itemsSubject.complete();
        this.totalItemsSubject.complete();
        this.loadingSubject.complete();
        this.emptySubject.complete();
    }
}