import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'cpfcnpj'
})
export class CpfCnpjPipe implements PipeTransform {
  transform(value: string, zenkaku: boolean = true): string {
    if(!value) return 
    value = value.toString()
    if (!value && value.length < 10) {
      return value;
    }
    if(value.length <  12){
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4");
    }else{
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5");
      
    }
  }
}