import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { takeUntil } from "rxjs/operators";


import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseConfigService } from "@fuse/services/config.service";


import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { User } from "app/entities/user";
import { UnsubscribeComponent } from "app/main/pages/unsunscribe.component";
import { AlertService } from "app/services/alert.service";
import { AuthService } from "app/services/auth.service";


@Component({
    selector: "toolbar",
    templateUrl: "./toolbar.component.html",
    styleUrls: ["./toolbar.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent
    extends UnsubscribeComponent
    implements OnInit, OnDestroy
{
    userStatusOptions: any[];
    user: User;


    /**
     * Constructor
     *
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _authService: AuthService,
        public _matDialog: MatDialog,
        private _alertService: AlertService,
        private _router: Router
    ) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._authService.currentUser$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user) => (this.user = user));
    }


    logout() {
        let dialog = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false,
        });


        dialog.componentInstance.confirmMessage = "Deseja efetuar logout?";


        dialog.afterClosed().subscribe((result) => {
            if (result) {
                this._authService.logout().subscribe(
                    (result) => {
                        this._alertService.success(
                            "Logout efetuado com sucesso.",
                            true
                        );
                        setTimeout(() => {
                            this._router.navigateByUrl("/auth/login");
                        }, 200);
                    },
                    (err) => {
                        this._alertService.error(err);
                    }
                );
            }
            dialog = null;
        });
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {}


    profile() {
        this._router.navigate(["users", this.user.id]);
    }


    toggleSidebar(): void {
        this._fuseSidebarService.getSidebar("navbar").toggleOpen();
    }
}



