import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search.component';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule
    ],
    exports: [
        SearchComponent
    ],
    declarations: [ SearchComponent ]
})
export class SearchModule
{
}
