import { Component, OnInit, Input, ViewEncapsulation, AfterContentInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FormGroup } from '@angular/forms';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil, distinctUntilChanged, startWith, debounceTime, filter, skip } from 'rxjs/operators';
import { AlertService } from 'app/services/alert.service';
 
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations,
})
export class SearchComponent implements OnInit, AfterContentInit, OnDestroy {

  @Input()
  public title = "BUSCA"

  @Input()
  public working = false

  private _formGroup: FormGroup = null

  @Input()
  public set formGroup(v : FormGroup) {
    this._formGroup = v;
    this.setupForm()
  }

  public get formGroup() : FormGroup {
    return this._formGroup
  }
  

  public get value() : any {
    return this._formGroup != null ? this._formGroup.value: {}
  }

  private changeSubject = new BehaviorSubject<any>(null);
  public formChange = this.changeSubject.asObservable();

  @Output() onFormChanged?= new EventEmitter<any>();
  
  protected _unsubscribeAll: Subject<any>;

  constructor(private alertService: AlertService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {

  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  public patchForm(data: any) {
    if(this.formGroup) {
      this.formGroup.patchValue(data, { emitEvent: false})
    }
  }

  private bindEvent() {
    if (!this.working) {
      this.changeSubject.next(this._formGroup.value)
      this.onFormChanged.emit(this._formGroup.value)
    }
  }

  private setupForm() {
    this._formGroup.valueChanges.pipe(
      takeUntil(this._unsubscribeAll),
      distinctUntilChanged(),
      startWith(null),
      skip(1),
      debounceTime(400),
      filter( _ => this._formGroup.valid )
      ).subscribe(v => {
        this.bindEvent()
      })
  }

  keyDownFunction(event) {
    if(event.keyCode == 13) {
      this._formGroup.updateValueAndValidity()
      if(this._formGroup.valid) {
        this.bindEvent()
      } else {
        this.alertService.error("Preencha os campos obrigatórios.")
      }
    }
  }

}
