import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { DialogComponent } from 'app/layout/components/dialog/dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private messageSubject$ = new Subject<any>();
  public message$ = this.messageSubject$.asObservable()
  private keepAfterNavigationChange = false;

  constructor(
    private router: Router,
    private dialog: MatDialog, 
    private snackBar: MatSnackBar)
  {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          this.keepAfterNavigationChange = false;
        } else {
          this.messageSubject$.next();
        }
      }
    });

    this.message$.subscribe(message => {
      if (message && message.text) {
        this.snackBar.open(message.text, "OK", {
          duration: 3000,
          panelClass: [ message.type ],
          horizontalPosition: "right",
          verticalPosition: "top"
        })
      } else {
        this.snackBar.dismiss()
      }
    })
    
  }

  success(message: any, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.messageSubject$.next({ type: 'teal-500', text: message });
  }

  remove() {
    this.messageSubject$.next();
  }

  error(errors: any, keepAfterNavigationChange = false) {
    let message = this.parseError(errors)
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.messageSubject$.next({ type: 'warn-500', text: message });
  }

  parseError(errors: any): String {
    if (Array.isArray(errors?.errors) && errors?.errors.length > 0) {
      errors = errors?.errors[0]

      if (Array.isArray(errors) && errors?.length > 0) {
        errors = errors[0]
      }
    }
       
    if (typeof errors.message == "string") {
      return errors.message
    } if (errors.error != undefined) {
      return errors.error.message
    } else if (typeof errors == "string") {
      return errors
    }
    return JSON.stringify(errors)
  }

  openDialog(data: any) {
    if (typeof data == "string") {
      data = {
        title: data
      }
    }
    if (!data.buttons) {
      data.buttons = ["Fechar", "OK"]
    }
    
    return this.dialog.open(DialogComponent, {
      autoFocus: false,
      disableClose: true,
      minWidth: 280,
      data: data
    })
  }
}
